import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as yup from "yup";
import common from './services/common';
import authAxios from './services/authAxios';
import baseURL from './services/baseUrl';
import ReactPaginate from 'react-paginate';
// import { useSortableData } from "./hooks";

const Test = () => {
    const [statementDetails, setStatementDetails] = useState([]);
    const [accountStat, setAccountStat] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState("");
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 

    const [startDate, setStartDate] = useState(new Date());
    const [endsDate, setEndsDate] = useState(new Date());
    var sevdayPre = new Date(common.minusDay(new Date(), 7))
    const nowDate = new Date()
    const prevesDate = new Date(sevdayPre)
    const UsFormatter = new Intl.DateTimeFormat('en-US')  

    const initialValues = {
        FromDate:  UsFormatter.format(prevesDate),
        ToDate:    UsFormatter.format(nowDate),
        // FromDate : new Date(sevdayPre).toLocaleDateString(),
        // ToDate : new Date().toLocaleDateString(),
        Type : "" || "1"  ,
    }
    const validationSchema = yup.object({
        FromDate: yup.string().required("Date One Req"),
        ToDate: yup.string().required("Date One Req"),
        Type: yup.string().required("Date One Req"),
    })
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {  
            AccountStatement(values); 
            console.log(values)
        }
    });
    const AccountStatement = async (values) => {
        await authAxios({
            method: "POST",
            url: `${baseURL}/AccountDetails/GetAccountStatement`,
            data: values,
        }).then(function (res) { 
            setAccountStat(res?.data?.data?.accountStDetails);
            // setFilteredCountries(res?.data?.data?.accountStDetails);
            console.log(res?.data?.data?.accountStDetails);
        }).catch((error) => {
            common.error(error);
        });
    }


    // const { items, requestSort, sortConfig } = useSortableData(props.data);
    const [currentPage, setCurrentPage] = useState(0); 
    // Pagination
        const PER_PAGE = 100;
        // const pageCount = Math.ceil(items.length / PER_PAGE);
        function handlePageClick({ selected: selectedPage }) {
            setCurrentPage(selectedPage);
        }
        const offset = currentPage * PER_PAGE;

    return ( 
    <>
        <section className="AccountStatement">
            <div className='HeadingOne'>
                <h4> Account Statement  </h4>
            </div> 
            <div className='CustomContainer'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="datePickerWrap">
                        <div className="datePicker">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date) 
                                    formik.setFieldValue(
                                        'FromDate', new Date(date).toLocaleDateString() 
                                    )
                                }} 
                            />
                            <span className='append'><i className="fal fa-calendar"></i></span>
                            <span className='append closeDate'><i className="far fa-times"></i></span>
                        </div> 
                    </div> 
                    <div className="datePickerWrap">
                        <div className="datePicker">
                            <DatePicker
                                selected={endsDate}
                                onChange={(date) => {
                                    setEndsDate(date)
                                    formik.setFieldValue('ToDate', new Date(date).toLocaleDateString())
                                }} 
                            />
                            <span className='append'><i className="fal fa-calendar"></i></span>
                            <span className='append closeDate'><i className="far fa-times"></i></span>
                        </div>
                    </div>
                    <select
                        name="reportType"
                        className="customSelect arrow"
                        {...formik.getFieldProps("Type")}
                    >
                        <option value="1"> All </option>
                        <option value="2"> Deposit/Withdraw Report </option>
                        <option value="3"> Game Report </option>
                    </select>
                    <button type="submit" className="btn submitBtn mt-2"> Submit </button>
                </form>
            </div>
        </section> 
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton> 
                <Modal.Title>
                    Client Ledger (Total Win Loss: {statementDetails?.map((d)=>Math.trunc(d?.winLoss))?.reduce((a,b)=>a+b,0)}) 
                    (Total Count {statementDetails?.length})
                    (Total Soda {statementDetails?.length})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='overflowHidden'>
                <table className="table table-bordered mt-2 statementDetailsPopup">
                    <thead>
                        <tr>  
                            <th className="text-left">no</th>
                            <th className="text-left">userName</th>
                            <th className="text-left">nation</th>  
                            <th className="text-left">side</th>
                            <th className="text-left">rate</th>
                            <th className="text-left">amount</th>
                            <th className="text-left">win / Loss</th>
                            <th className="text-left">place Date</th>
                            <th className="text-left">match Date</th>
                            <th className="text-left">IP </th>
                            <th className="text-left">browser Details</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {statementDetails ? statementDetails.map((data, key) => (
                        <tr className={data?.side == "BACK" ? "sky" : "pink02 "} key={key}>
                            <td className="text-left">{key + 1}</td>
                            <td className="text-left">{data?.userName}</td>
                            <td className="text-left">{data?.nation}</td>
                            <td className="text-left">{data?.side}</td>
                            <td className="text-left">{data?.rate}</td>
                            <td className="text-left">{data?.amount}</td>
                            <td className={Math.trunc(data?.winLoss) >= 0 ? (Math.trunc(data?.winLoss == 0) ? "text-black" : "text-success" )  : 'text-danger'}>
                                {Math.trunc(data?.winLoss)} 
                            </td>
                            <td className="text-left">{data?.placeDate}</td>
                            <td className="text-left">{data?.matchDate}</td>
                            <td className="text-left">{data?.ipAddress}</td>
                            <td className="text-left">{data?.browserDetails}</td>
                        </tr>
                        ))
                        :  
                        <tr>
                            <td className='resultNotFound text-center' colSpan="11"> There are no records to show </td> 
                        </tr>
                        }
                    </tbody>
                </table>
            </div> 
            </Modal.Body> 
        </Modal> 
            <section className='customDataTableWrap'>
            
            <table>
                <thead>
                <tr>
                    <th><button type="button" > Form Name </button> </th>
                    <th><button type="button" > Category </button> </th>
                </tr>
                </thead>
                <tbody>
                {accountStat.length === 0 ? (
                    <tr>
                    <td colspan="2" >
                        No matching records found
                    </td>
                    </tr>
                ) : (
                    accountStat.map((item, index) => (
                    <tr key={index}  >
                        <td>{item.balance}</td>
                        <td>{item.credit}</td>
                    </tr>
                    ))
                )}
                </tbody>
            </table>
                
            <div>
                <span>
                    {/* Showing {offset + 1} to {offset + PER_PAGE} of {items.length}{" "} */}
                    entries
                </span>
            </div>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                // pageCount={pageCount}
                onPageChange={handlePageClick}
                // containerClassName={styles.pagination}
                // previousLinkClassName={styles.paginationLink}
                // nextLinkClassName={styles.paginationLink}
                // disabledClassName={styles.paginationDisabled}
                // activeClassName={styles.paginationActive}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
            />
            {/* 
            <DataTable
                className='customDataTable'
                columns={columns}
                data={filteredCountries} 
                pagination   
                paginationServer 
                subHeader 
                subHeaderComponent={
                    <label> Search: 
                    <input
                        type="text"
                        placeholder='Type to Search'
                        className='w-100 form-control'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    </label>
                }
                subHeaderAlign="right"
                handlePerRowsChange  
            />  
            */}
        </section>
    </>
  )
}

export default Test;