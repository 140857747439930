import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import baseURL from "./baseUrl";
import common from "./common";
 
const bodyAnimation = (type, loader) => {
  if (type == "add" && loader) {
    document.body.className = "loading_page"; 
  } else if (type == "remove" && loader) {
    document.body.className = document.body.className.replace("loading_page", ""); 
  }
};
// const DbodyAnimation = (Dtype, darkLoader) => {
//   if (Dtype == "add" && darkLoader) {
//     document.body.className = "Darkloader"; 
//   } else if (Dtype == "remove" && darkLoader) {
//     document.body.className = document.body.className.replace("Darkloader", ""); 
//   }
// }
const authAxios = axios.create({
  baseURL: baseURL
});

authAxios.interceptors.request.use((config) => {
    bodyAnimation("add", config.loader);
    // DbodyAnimation("add", config.darkLoader);
  
    let token = reactLocalStorage.get("token");
    let headers = {
      baseURL: baseURL,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
     // Exclude FormData from encryption
     if (config.data && !(config.data instanceof FormData)) {
      let postData = config.data;
      config.data = common.encrypt(postData);
  }
    config.headers = headers;
    return config;
  },
  (error) => {
    console.log("reqErrr", error);
    return Promise.reject(error);
  }
);
const errorshow = (err) => {
  let errHndle = err.response != undefined ? true : false;
  if (errHndle == true && err.response.status === 401) { 
    let urlParams = new URLSearchParams(window.location.search);
    let ssr = urlParams.get("ssr");
    if (ssr != 1) {
      reactLocalStorage.clear("token");
      window.location.href = "/";
    }
  } 
};
authAxios.interceptors.response.use( (response) => {
  bodyAnimation("remove", response.config.loader);
  // DbodyAnimation("remove", response.config.darkLoader);
    return response;
  },
  (error) => {
    var errorType = error.response != undefined ? error.response.config.loader : true;
    // var DerrorType = error.response != undefined ? error.response.config.darkLoader : true;
    bodyAnimation("remove", errorType);
    // DbodyAnimation("remove", DerrorType);
    errorshow(error);
    return Promise.reject(error);
  }
);
authAxios.interceptors.response.use(
  undefined,
  function axiosRetryInterceptor(err) {
    var errorType = err.response != undefined ? err.response.config.loader : true;
    // var DerrorType = err.response != undefined ? err.response.config.darkLoader : true;
    bodyAnimation("remove", errorType);
    // DbodyAnimation("remove", DerrorType);
    errorshow(err);
    return Promise.reject(err);
  }
);
export default authAxios;