import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { reactLocalStorage } from "reactjs-localstorage";
import authAxios from "../services/authAxios";
import baseURL from "../services/baseUrl";
import common from "../services/common";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(true);
  const [userData, setUserData] = useState({});
  const [balance, setBalance] = useState({});
  const [infoModal, setInforModal] = useState(false);
  const [welModal, setWelModal] = useState(false);
  const [betSlipModal, setBetslipModal] = useState(false);
  const [betSlips, setBetSlips] = useState([]);
  const [betSlipData, setBetSlipData] = useState({});
  const [globalOdds, setGlobalOdds] = useState({});
  const [oddsBetList, setOddsBetList] = useState([]);
  const [tossBetList, setTossBetList] = useState([]);
  const [bookBetList, setBookBetList] = useState([]);
  const [sessionBetList, setSessBetList] = useState([]);
  const[resdata,setResData]=useState([]);
  const[tennisdata, setTennisData] = useState([])
  const[profitlossData, setProfitLossData]=useState({ values: '', lay: '' });
  const[profitlossData11, setProfitLossData1]=useState({player1:'',player2:'',player3:''});
  const[clearamount, setClearAmount]= useState({})
  const[bidamount, setBidAmount]= useState({})
  const[popupmatch,setPopupMatch]=useState({})
  const[matchodsOPtimize,SetMatchOddsOptimize]=useState({})
  const[dashboardData,setDashboardData] =useState({})
  const[dashboardData1,setDashboardData1] =useState([])
  const[loader,SetLoader] =useState(false)


  // const navigate = useNavigate();

  function LogOutB() {
    authAxios({
      method: "GET",
      url: `/User/Logout`,
    })
      .then(function (response) {
        setLogin(false);
        navigate("/");
        // reset()
        // console.clear()
      })
      .catch(function (error) {
        common.error(error);
      });
  }
  const reset = () => {
    setLogin(false);
    setBetslipModal(false);
    setBetSlipData({})
    setBetSlips([])
    setUserData({})
    setBalance({})
    setGlobalOdds({})
    localStorage.clear();
    LogOutB();

    setOddsBetList([]);
    setTossBetList([]);
    setBookBetList([]);
    setSessBetList([]);

    getTossHistory();
    getSessionHistory();
    getBookHistory();
    getOdsHistory();
    setProfitLossData({});
    setProfitLossData1({});
    setClearAmount({});
    setBidAmount({});
    setPopupMatch({});
    SetMatchOddsOptimize({});
    setDashboardData({});
    setDashboardData1({});
    SetLoader(false)
  };

  function BalanceData(id) {
    let postData = { sessionId: id };
    //console.log(postData);
    authAxios({
      method: "POST",
      url: `/User/GetBalance`,
      data: postData,
    })
      .then(function (responce) {
        if (responce?.data?.status === 1) {
          reset();
        } else {
          let resData = responce?.data?.data || {};
          setBalance(resData);
        }
      }).catch(function (error) {
        common.error(error);
      });
  }

  useEffect(() => {
    let authLocal = localStorage.getItem("token");
    let userType = localStorage.getItem("userType");

    let name = localStorage.getItem("name");
    let sessionId = localStorage.getItem("sessionId");
    let profData = {
      userType: userType,
      sessionId: sessionId,
      name: name,
    };
    setUserData(profData);
    setLogin(authLocal);
  }, []);

  useEffect(() => {
    // console.log(userData?.sessionId);
    if (isLogin && userData?.sessionId) {
      BalanceData(userData?.sessionId);
    }
    let intrVal;
    if (isLogin) {
      intrVal = setInterval(() => {
        userData?.sessionId && BalanceData(userData?.sessionId)
      }, 10000);
    }
    return () => clearInterval(intrVal);
  }, [isLogin, userData]);

  let authLocal = localStorage.getItem("token");
  useEffect(()=>{
    if(authLocal){
      setLogin(true);
    }
  },[])
  // console.log(isLogin)

  
  
const getOdsHistory = async (Matchid) => {  
  let postData = { "Id": Matchid?.toString() } 
  await authAxios({
    method: "POST",
    url: `${baseURL}/Player/GetOddsBetList`,

    data: postData,
  })
  .then(function (response) {
      setOddsBetList(response?.data?.data  || [])
  })
  .catch((error) => {
    common.error(error);
  })
}
const getTossHistory = async (Matchid) => { 
  let postData = {"Id": Matchid?.toString()} 
  await authAxios({
      method: "POST",
      url: `${baseURL}/Player/GetTossBetList`,
      data: postData,
  }).then(function (response) {
    setTossBetList(response?.data?.data )  
      // setTossBetList(response?.data?.data  || [])  
  }).catch((error) => {
      common.error(error);
  })
} 
const getBookHistory = async (Matchid) => { 
  let postData = {
      "Id": Matchid?.toString()
  } 
  await authAxios({
      method: "POST",
      url: `${baseURL}/Player/GetBookBetList`,
      data: postData,
  }).then(function (response) {
      setBookBetList(response?.data?.data)
      // console.log(response?.data?.data);
  }).catch((error) => {
      common.error(error);
  })
}
const getSessionHistory = async (Matchid) => {
    // console.log(betSlipData?.SessionId.toString());
    let postData = {
        "Id": Matchid?.toString()
    } 
    // console.log(postData)
    await authAxios({
        method: "POST",
        url: `${baseURL}/Player/GetSessionBetList`,
        data: postData,
    }).then(function (response) {
        setSessBetList(response?.data?.data)
        // console.log(response?.data?.data);
    }).catch((error) => {
        common.error(error);
    })
}

  
  return (
    <UserContext.Provider
      value={{
        isLogin, setLogin, reset,
        userData, setUserData,
        infoModal, setInforModal,
        balance, setBalance,
        welModal, setWelModal,
        betSlipModal, setBetslipModal,
        betSlips, setBetSlips,
        betSlipData, setBetSlipData,
        globalOdds, setGlobalOdds,
        tennisdata, setTennisData,
        
        getOdsHistory, 
        getTossHistory,
        getBookHistory,
        getSessionHistory,

        oddsBetList, setOddsBetList,
        tossBetList, setTossBetList,
        bookBetList, setBookBetList,
        sessionBetList, setSessBetList,
        resdata,setResData,
        profitlossData, setProfitLossData,
        profitlossData11, setProfitLossData1,
        clearamount, setClearAmount,
        bidamount, setBidAmount,
        popupmatch,setPopupMatch,
        matchodsOPtimize,SetMatchOddsOptimize,
        dashboardData,setDashboardData,
        dashboardData1,setDashboardData1,
        loader,SetLoader,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
