import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Link, useNavigate, Routes, Route, NavLink } from "react-router-dom";
import { UserContext } from "../../context/LoginContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import common from "../../services/common";
import unAuthAxios from "../../services/unAuthAxios";
import AbortController from "abort-controller"
import TermsUI from "../Terms/TermsUI";

const FormData = () => {
  const { isLogin,setLogin, setUserData, setWelModal } = useContext(UserContext);
  const navigate = useNavigate();
  const ac = new AbortController();
  /* USE YUP */
  const validationSchema = yup.object({
    UserName: yup.string().required(""),
    // UserName: yup.string().matches(/^\S*$/, `${formik.errors.UserName}`).required(""),
    Password: yup.string().required(" "),
  });

  /* USE FORMIK */
  const formik = useFormik({
    initialValues: {
      UserName: "",
      Password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // console.log(values);
      // let localIp = await publicIp.v4();
      let LoginData = {
        ...values,
        IpAddress: "1.1.1.1",
      };

      let encrData = common.encrypt(LoginData);
      login(encrData);
      // resetForm({ values: "" })
    },
  });

  /* USE LOGIN BTN */
  const [loader, setLoader] = useState(true);
  const notify = (vall) => toast(vall);
  const login = (payload) => {
    //  encrData = common.encrypt(payload);
    unAuthAxios({
      method: "POST",
      url: `/Authenticate/Login`,
      data: payload,
    }).then(function (response) {
      if (response.data.status == 0) {
        common.loader(true);
        setLogin(true);
        setWelModal(true);
        let resData = response?.data || {};
        setUserData(resData);
        localStorage.setItem("name", resData.name);
        localStorage.setItem("sessionId", resData.sessionId);
        localStorage.setItem("token", resData.token);
        localStorage.setItem("userType", resData.userType);
        navigate("/");
      } else if (response.data.status == 1) {
        notify(response.data.message);
        setLoader(false);
        formik.resetForm();
        setTimeout(() => {
          setLoader(true);
        }, 5000);
      } else {

      }
      common.loader(false);
    }).catch(function (error) {
      common.error(error);
    });
    
  };

  const demologin=(demodata)=>{
    let  encrData = common.encrypt(demodata);
    unAuthAxios({
      method: "POST",
      url: `/Authenticate/LoginWithDemo`,
      data: encrData
    }).then(function (response) {
      if (response.data.status == 0) {
        common.loader(true);
        setLogin(true);
        setWelModal(true);
        let resData = response?.data || {};
        setUserData(resData);
        localStorage.setItem("name", resData.name);
        localStorage.setItem("sessionId", resData.sessionId);
        localStorage.setItem("token", resData.token);
        localStorage.setItem("userType", resData.userType);
        navigate("/");
      } else if (response.data.status == 1) {
        notify(response.data.message);
        setLoader(false);
        formik.resetForm();
        setTimeout(() => {
          setLoader(true);
        }, 5000);
      } else {

      }
      common.loader(false);
    }).catch(function (error) {
      common.error(error);
    });
  }


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        
        <ToastContainer position="top-center"
          autoClose={4000}
          className="customAdd"
        />
       <h4 class="text-center login-title">Login <i class="fas fa-hand-point-down"></i></h4>


        <div className="form-group mb-4 input-group position-relative">
          <input type="text" placeholder="Username"  autoComplete="on"
            {...formik.getFieldProps("UserName")}
          /><span class="input-group-text"><i class="fas fa-user"></i></span>
          {formik.touched.UserName && formik.errors.UserName ? (<div>{formik.errors.UserName}</div>) : null}
          
        </div>


        <div className="form-group input-group position-relative">
          <input type="password" placeholder="Password"  autoComplete="on"
            {...formik.getFieldProps("Password")}
          /><span class="input-group-text"><i class="fas fa-key"></i></span>
          {formik.touched.Password && formik.errors.Password ? (<div>{formik.errors.Password}</div>) : null}
        </div> 
        <div className="group_but" style={{marginBottom:"3px"}}>
          <button type="submit" className="login_btn">
            Login
            {
              loader == true ? (<i class="fas fa-sign-in-alt float-end mt-1" style={{marginRight:"5%" }}></i>)
              :
              (<i className="fas fa-spinner fa-spin"></i>)
            }
          </button>
        
        </div> 
        <div className="group_but" style={{marginTop:"5px"}}>
        <button type="button"  className="login_btn" onClick={() => demologin({ UserName: "demo", Password: "123", IpAddress: "1.1.1.1" })}>
            Login with Demo ID 
            {
              loader == true ? (<i class="fas fa-sign-in-alt float-end mt-1" style={{marginRight:"5%" }}></i>)
              :
              (<i className="fas fa-spinner fa-spin"></i>)
            }
          </button>
          </div>
        <div className="privacy_policy text-center">
          <p> This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" target="_blank"> Privacy Policy </a> and
            <a href="https://policies.google.com/terms" target="_blank"> Terms of Service </a> apply.
          </p>
        </div>
      </form>
    <TermsUI/>
    </>
  );
};

const AdminLogin = (props) => {
  const [abortitnow,setabortItNow]=useState();
  const ac = new AbortController();
  const [show, setShow] = React.useState(false);
  const loadApp = async () => {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        setShow(true);
        resolve();
      }, 1000);
    });
    await promise;
  };
  const loadappfunction=()=>{
    loadApp();
    setabortItNow(true);
  }
  useEffect(() => {
    loadappfunction();
    return () => {
      setabortItNow({}); // Can't perform a React state update on an unmounted component solution
    };
  }, []);

  if (!show) {
    return (
      <>
        <section className="Login_outer loginWrap">
          <div className="loginInner">
            <img src="/assets/logo.png" className="logo" />
            <i className="fas fa-spinner fa-spin window__load"> </i>
          </div>
        </section>
      </>
    )
  }


  return (
    <>
      <section className="Login_outer loginWrap">
        <div className="loginInner">
          <img src="/assets/logo.png" className="logo" />
          {show && (
            <Suspense fallback={<i className="fas fa-spinner fa-spin window__load"> </i>}>
              <FormData getval={props} />
            </Suspense>
          )}
        </div>
      </section>
    </>
  )
};

export default AdminLogin;

