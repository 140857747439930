import React from 'react';
import "./style.css" ;
const TermsUI = () => {
  return (
    <>
      <section class="footer footer-login">
          <div class="footer-top">
            <div class="footer-links">
              <nav class="navbar navbar-expand-sm">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="/terms-and-conditions" target="_blank"> Terms and Conditions </a>
                    </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/responsible-gaming" target="_blank"> Responsible Gaming </a>
                  </li>
                </ul>
              </nav>
            </div>
            </div>
            <div class="support-detail"><h2>24X7 Support</h2><p></p></div>
          <div class="social-icons-box">                        
          </div>
       </section>
    </>
  )
}

export default TermsUI;