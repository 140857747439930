import cryptoJs from "crypto-js";
import { toast } from "react-toastify";

let common = {
  error: (error) => {
    let err = error.request.response;
    err = JSON.parse(err).message;
    toast.error(err);
  },
  loader: (type) => {
    if (type) {
      document.body.className = "loading_page"; 
    } else {
      document.body.className = document.body.className.replace("loading_page", ""); 
    }
  },
  // darkLoader: (Dtype) => {
  //   if (Dtype) {
  //     document.body.className = "Darkloader"; 
  //   } else {
  //     document.body.className = document.body.className.replace("Darkloader", ""); 
  //   }
  // }, 
  encrypt: (data) => {
    // console.log(process.env.REACT_APP_ENCRIPT_KEY, process.env.REACT_APP_ENCRIPT_IV)
    let key = cryptoJs.enc.Utf8.parse(process.env.REACT_APP_ENCRIPT_KEY);
    let iv = cryptoJs.enc.Utf8.parse(process.env.REACT_APP_ENCRIPT_IV);

    let dataEnc = JSON.stringify(data);
    dataEnc = cryptoJs.enc.Utf8.parse(dataEnc);
    let encryptedlogin = cryptoJs.AES.encrypt(dataEnc, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: cryptoJs.mode.CBC,
      padding: cryptoJs.pad.Pkcs7,
    });
    return {
      data: encryptedlogin.toString(),
    };
  },
  addDay: (date, day) => {
    return new Date(new Date(date).setDate(new Date(date).getDate() + day))
  },
  minusDay: (date, day) => {
    return new Date(new Date(date).setDate(new Date(date).getDate() - day))
  },

 
};

export default common;
