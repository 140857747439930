import React, { useState,useEffect,useContext } from 'react';
import { Button, DropdownButton, Modal } from 'react-bootstrap';

const Footer = () => {
    const [showResult, setShowResult] = useState(false);
    const handleClose = () => setShowResult(false);
    const handleShow = () => setShowResult(true);
  return (
    <>
    <div class="footer-bottom">
        <div class="secure-logo">
            <div>
                <img src="https://wver.sprintstaticdata.com/v3/static/front/img/ssl.png"/>
            </div>
            <div class="ml-2">
                <b>100% SAFE</b>
            <div>Protected connection and encrypted data.</div>
            </div>
        </div>
        <div class="d-inline-block">
            <button class="btn p-0">
                <img src="https://versionobj.ecoassetsservice.com/v13/static/front/img/18plus.png" onClick={handleShow}/>
                </button><a href="https://www.gamcare.org.uk/" target="_blank">
                <img src="https://versionobj.ecoassetsservice.com/v13/static/front/img/gamecare.png"/>
                </a><a href="https://www.gamblingtherapy.org/" target="_blank"
                ><img src="https://versionobj.ecoassetsservice.com/v13/static/front/img/gt.png"/></a>
        </div>
    </div>
    <div class="footer-text">
        <p></p>
        <p class="text-center">© Copyright 2024. All Rights Reserved. Powered by BigDaddy.
        </p>
    </div>
    <Modal className='casinoresult_modal' show={showResult} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> BigDaddy.in </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="modal-18plus p-5">
                    <div>
                        <img src="https://versionobj.ecoassetsservice.com/v13/static/front/img/18plus.png" alt="18+ only" />
                    </div>
                    <p><b>Over 18s only</b></p>
                    <br/>
                    It is an offence for anyone under the age of 18 to open an account or to gamble on countyexch.com. countyexch.com takes its age-verification responsibilities very seriously. We carry out age-verification checks on all customers who use payment mechanisms which are available to under 18s and additionally perform random age-verification checks on customers using other forms of funding mechanisms. We may ask for information to verify your age and could restrict or suspend your account until your age is confirmed.
                    <br/><br/>
                    PLEASE NOTE THAT ANYONE UNDER THE AGE OF 18 YEARS OLD FOUND TO BE USING THIS SITE WILL HAVE ANY WINNINGS FORFEITED AND MAY ALSO BE REPORTED TO THE POLICE.
                    <br/><br/>
                    <b>Filtering systems</b>
                    <br/><br/>
                    BigDaddy.com advises and encourages its customers to prevent minors from accessing gambling websites.
                    <br/><br/>
                    Filtering solutions allow parents to regulate access to the internet, based on chosen criteria. Parents can use filters to prevent their children from accessing, amongst other things, gambling websites. Because our pages are 'labelled', filtering solutions being used to prevent gambling access are able to detect our site content, and block our pages. If you share your computer with friends or family who are under the legal age to register or bet with our site, please consider parental filtering solutions such as:
                    <br/><br/>
                    Net Nanny™ <a href="https://www.netnanny.com" target="_blank">www.netnanny.com</a>
                    <br/><br/>
                    CyberPatrol <a href="https://www.cyberpatrol.com" target="_blank">www.cyberpatrol.com</a>
                </div>

                </Modal.Body>
            </Modal>
    </>
  )
}

export default Footer;